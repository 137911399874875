import React, { Component } from "react";
import Img from "gatsby-image";
import moment from "moment";

export default class Awards extends Component {
    render() {
        const { data } = this.props;
        return (
            <div className="work section" id="Awards">
                <div className="container">
                    <div className="section-head">
                        <h2 className="text-center">Awards</h2>
                    </div>
                    <ul className={`blogs-list ${data.edges.length < 5 ? "few-blogs" : ""}`}>
                        {data.edges.map((item, index) => {
                            return (
                                <li key={index} className="item">
                                    <div className="inner">
                                    <a className="link" href={item.node.url} target="_blank" rel="noopener noreferrer" />
                                        {item.node.image ? (
                                            <Img
                                                fixed={item.node.image.fluid}
                                                objectFit="cover"
                                                objectPosition="50% 50%"
                                            />
                                        ) : (
                                                <div className="no-image"></div>
                                            )}
                                        <div className="details">
                                            <h3 className="title">{item.node.title}</h3>
                                            <div
                                                dangerouslySetInnerHTML={{
                                                    __html: item.node.description.childMarkdownRemark.html
                                                }}
                                            />
                                            <div>
                                                <span className="date">
                                                    <i className="fas fa-calendar-alt"></i>{" "}
                                                    {moment(item.node.date).format("LL")}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            );
                        })}
                    </ul>
                </div>
            </div>
        );
    }
}

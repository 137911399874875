import React, { Component } from "react";
import { Link } from "gatsby";
import moment from "moment";

export default class Projects extends Component {
    render() {
        const { data } = this.props;
        return (
            <div className="service section" id="Projects">
                <div className="container">
                    <div className="section-head">
                        <h2>Projects</h2>
                    </div>
                    <div className="row">
                        {data.edges.map((item, index) => {
                            return (
                                <div key={index} className="col-md-12 mb-3">
                                    <div className="project-main">
                                        <div className="project-title">
                                            <h3>{item.node.name}</h3>
                                            <div>
                                                <span className="date">
                                                    <i className="fas fa-calendar-alt"></i>{" "}
                                                    {moment(item.node.date).format("LL")}
                                                </span>
                                            </div>
                                        </div>
                                        <h6>{item.node.tools}</h6>
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: item.node.description.childMarkdownRemark.html
                                            }}
                                        />
                                        <div className="see-more">
                                            <a href={item.node.url} target="_blank">
                                                <span>See the Project</span>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        );
    }
}

import React, { Component } from "react";
import { Link } from "gatsby";
import moment from "moment";

export default class work extends Component {
  render() {
    const { data } = this.props;
    return (
      <div className="work section" id="Work">
        <div className="container">
          <div className="section-head">
            <h2 className="text-center">Work Experience</h2>
          </div>
          <ul className="work-list">
            {data.edges.map((item, index) => {
              return (
                <div key={index} className="col-md-12 mb-3">
                <div className="work-main work-hover">
                  <a className="link" href={item.node.url} target="_blank" rel="noopener noreferrer" />
                    <h3>{item.node.title}</h3>
                    <h6>{item.node.duration}</h6>
                    <h6>{item.node.place}</h6>
                    <div
                        dangerouslySetInnerHTML={{
                            __html: item.node.description.childMarkdownRemark.html
                        }}
                    />
                    
                </div>
            </div>
              );
            })}
          </ul>
        </div>
      </div>
    );
  }
}
